import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/page.scss");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/AgentContacts.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Error.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/HeaderAction.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/HeaderSub.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/LanguageSelector.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/Redirect.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/ReportIssueCard.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/ShareLinkButton.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/signin/SignIn.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/TelegramBotLogin.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/TelegramLogin.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/TranslationProvider.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/YandexMetrica.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/dashboard_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/delete_FILL1_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/domain_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/garage_home_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/grid_view_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/group_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/home_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_add_photo.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_check_green_circle.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_invalid_red_circle.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_menu.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_no_entry.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_note_grey_circle.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_search.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_selected.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_share.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/icon_sulafat.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/logo.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/meeting_room_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/moving_ministry_FILL0_wght300_GRAD0_opsz24_edited.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/psychiatry_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/select_all_FILL0_wght300_GRAD0_opsz24.svg");
;
import(/* webpackMode: "eager" */ "/workspace/src/media/stairs_FILL0_wght300_GRAD0_opsz24.svg");
