'use client';
import {Card} from "@/components/Card";
import {useTranslation} from "react-i18next";
import {GroupIcon} from "@/icons";
import {Error} from "@/components/Error";
import {ParseKeys} from "i18next";
import {TelegramBotLogin} from "@/components/TelegramBotLogin";

type Props = {
    redirect: string;
    errorMessage: ParseKeys | undefined;
};

export function SignIn({redirect, errorMessage = undefined}: Props) {
    const {t} = useTranslation();
    return (
        <>
            <div className="tw-text-center tw-font-bold tw-text-2xl/[50px] tw-mb-12">
                {t('signIn.line1')}<br/>
                {t('signIn.line2')}<br/>
                {t('signIn.line3')}<br/>
            </div>
            <Card className="tw-p-6">
                <div className="tw-text-main-violet tw-font-bold tw-text-[32px]/[44px]">
                    530 <GroupIcon className="tw-w-8 tw-h-8 fill-current tw-fill-main-violet tw-inline-block"/>
                </div>
                <div className="tw-font-medium tw-text-sm tw-mb-6">{t('signIn.cardText')}</div>
                <TelegramBotLogin redirect={redirect} className="tw-w-full tw-justify-center tw-fill-white"/>
                {errorMessage && <Error message={errorMessage}/>}
            </Card>
            <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-2.5 tw-mt-8">
                <img src="/images/agencies/1-remax.svg" alt="RE/MAX" className="tw-h-8"/>
                <img src="/images/agencies/2-romanescu.png" alt="Romanescu&Co" className="tw-h-8"/>
                <img src="/images/agencies/3-elite.svg" alt="Elite Realty" className="tw-h-8"/>
                <img src="/images/agencies/4-top-estate.svg" alt="Top Estate" className="tw-h-8"/>
                <img src="/images/agencies/5-citygrup.svg" alt="CityGrup" className="tw-h-8"/>
                <img src="/images/agencies/6-okey-imobil.png" alt="Okey Imobil" className="tw-h-8"/>
                <img src="/images/agencies/7-inhub.svg" alt="inHub" className="tw-h-8"/>
                <img src="/images/agencies/8-cartier.png" alt="Cartier Imobil" className="tw-h-8"/>
                <img src="/images/agencies/9-air.svg" alt="AIR" className="tw-h-8"/>
                <img src="/images/agencies/9-realist.svg" alt="Realist" className="tw-h-8"/>
                <img src="/images/agencies/12-express.svg" alt="Express" className="tw-h-8"/>
                <img src="/images/agencies/13-proteam.svg" alt="ProTEAM" className="tw-h-8"/>
                <img src="/images/agencies/14-loyal.svg" alt="Loyal" className="tw-h-8"/>
                <img src="/images/agencies/14-set.svg" alt="Set" className="tw-h-8"/>
            </div>
        </>
    );
}
